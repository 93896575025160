.contorno{
    height: 15rem;
    background-color: #2D447E;
    color: whitesmoke;
    font-weight: bold;
    width: 100%;
}
.facu{
    color: black;
    text-align: center;
 
}
