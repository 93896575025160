.container {
    display: flex;
    align-items: center;
    
}
.btn{
    height: 50px;
    width: 70px;
    border-radius: 7px;
    margin-left: 20px;
    color: white;
    background-color: #159BD6;
}

.input {
    width: 380px;
    height: 50px;
    border-radius: 7px;
}

@media (max-width: 1150px) {
    .input {
        width: 300px;
        margin-left: 5px;
    }
    .btn {
        width: 60px;
        margin-right: 10px;
        
    }
    
  }