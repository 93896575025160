.fondo{
    background-color:#F6F8FA;
  }
.divContainer {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    gap: 100px;
    place-content: center;
    
}
.input{
    margin: 0;
    width: 322px;
    height: 40px;
    position: relative;
    border-radius: 5px;
    background-color: white;
    padding-left: 5px;
}
.divContainer ul{
    list-style: none;
    padding-left: 0px;
}
.contact {
    margin-top: 100px;
    text-align: center;
}

.btn {
    color: white;
    border: none;
    cursor: pointer;
    width: 120px;
    height: 50px;
    margin-bottom: 30px;
    background-color: #3A497E;
    font-weight: bold;
    border-radius: 5px;
    
}


.btn2 {
    color: white;
    border: none;
    cursor: pointer;
    width: 120px;
    height: 40px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 30px;
    background-color: rgb(80, 95, 214);
    border-radius: 5px;
    
}
.question {
    border: 1px solid black;
    padding-left: 10px;
    padding-top: 5px;
    width: 95%;
    border-radius: 5px;
    margin-top: 12px;

}
.answer {
    width: 95%;
    border: 1px solid black;
    padding-left: 10px;
    padding-top: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
}

@media (max-width: 500px) {
    .divContainer {
        flex-direction: column;
        text-align: center;
        gap: 50px;
        margin-top: 10px;
    }
    .answer {
        margin-left: 10px;
    }
    .question {
        margin-left: 10px;
    }
    .btn2 {
        margin-left: 0;
    }
    .input{
        margin-top: 20px;
    }
}