.containerCard{
    display: flex;
    flex-direction:row;
    justify-content: space-evenly;
    align-items: center;    
}

@media (max-width: 500px) {
    .containerCard{
        flex-direction:column;
    }
}

.fondo{
    background-color: #F6F8FA;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    overflow: auto;
    width: 100%;
    height: 100%;
  }


  html, body {
    width: 100%;
    padding: 0;
    margin: 0;
    height: 100%;
}

