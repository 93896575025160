.fondo{
    background-color: rgb(255, 250, 250);
    width: 100%;
    height: 120%;
  }
.containerContact {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.formContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 17px;
    align-items: center;
    
}
.info {
    text-align: center;
    width: 50%;
}

.info h1{
    margin-top: 50px;
    margin-bottom: 30px;
}
.input{
    margin: 0;
    width: 380px;
    height: 40px;
    position: relative;
    border-radius: 5px;
    background-color: white;
    padding-left: 5px;
}

.btn {
    color: white;
    border: none;
    cursor: pointer;
    width: 380px;
    height: 40px;
    margin-top: 30px;
    background-color: rgb(80, 95, 214);
    border-radius: 5px;
}
.btn:hover {
    animation: pulsate 1.5s ease-in-out;
    cursor: pointer;
}
@keyframes pulsate {
    0% {
        box-shadow:
        0 0 25px #5ddcff,
        0 0 50px #4e00c2;
    }
}

@media (max-width: 500px) {
    .containerContact{
        flex-direction: column;
    }
    .formContainer {
        width: 100%;
        margin-top: 50px;
        margin-bottom: 100px;
    }
}
.mapa {
    
    margin-left: 25px;
    width: 80%;
   height: 100%;
   margin-top: 10px;
   margin-block-end: 50px;
   border-radius: 0.5rem;
}