.btn {
    border: none;
    cursor: pointer;
    width: 120px;
    height: 40px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 30px;
    background-color: #3A497E;
    border-radius: 5px;
    color: white;
    font-weight: bold;
}
.link {
    color: white;
    text-decoration: none;
}