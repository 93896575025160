.image {
    width: 250px;
    height: 250px;
    border-radius: 100%;
}

.containerPerfil{
    display: flex;
    flex-direction: row;
    width: 100%;
    place-content: center;
    gap: 10%;
    height: 100vh;
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}



.input{
    margin: 0;
    width: 322px;
    height: 50px;
    position: relative;
    border-radius: 5px;
    background-color: white;
    padding-left: 5px;
}

.btn {
    color: white;
    border: none;
    cursor: pointer;
    width: 90px;
    height: 50px;
    margin-left: 30px;
    background-color: #3A497E;
    border-radius: 5px;
    font-weight: bold;
}

.btn2 {
    color: white;
    border: none;
    cursor: pointer;
    width: 90px;
    height: 60px;
    margin-left: 30px;
    background-color: #3A497E;
    border-radius: 5px;
}
.none {
    display: none;
}
.prf {
    font-size: 18px;
}


@media (max-width: 1300px) {
    .containerPerfil{
        flex-direction: column;
    }
    .container2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }
    .btn{
        width: 390px;
        margin-left: 10px;
    }
    .input {
        width: 390px;
        margin-bottom: 15px;
        margin-left: 10px;
    }
    .btn2{
        margin-bottom: 100px;
        width: 390px;
        margin-left: 10px;
    }
    .image {
        width: 250px;
        height: 250px;
        margin-bottom: 30px;
    }
    .prf {
        font-size: 20px;
        margin-left: 10px;
    }
}
.fondo{
    background-color: #F6F8FA;
  }

  