.cartItem {
    border: 1px solid black;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    margin-top: 20px;
    height: auto;
    width: auto;
}
.image{
    margin-top: 10px;
    margin-bottom: 10px;
}
.prf {
    margin-right: 10px;
}

.count {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 10px;
    margin-left: 10px;
    margin-top: 10px;
}

.countMas {
    width: 27px;
    border-radius: 5px;
    background-color: rgb(80, 95, 214);
    color: white;
}
.countMenos {
    width: 27px;
    background-color: rgb(80, 95, 214);
    color: white;
    border-radius: 5px;  
}
.cartItemDetails {
    margin-left: 40px;
    margin-top: 10px;
}
.cartItemQty{
    margin-top: 20px;
    
}
.cartItemActions {
    
    margin-left: 40px;
}

.input {
    width: 59px;
    margin-right: 30px;
    margin-left: 20px;
    padding-left: 12px;
}

.actions__deleteItemBtn {
    background: none;
    border: none;
}

@media (max-width: 500px) {
    .cartItem{
        width: 380px;
        margin-left:17px;
        flex-direction: column;
    }
}