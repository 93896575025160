.btn {
    color: white;
    border: none;
    cursor: pointer;
    width: 90px;
    height: 50px;
    background-color: rgb(58, 73, 126);
    border-radius: 10px;
    margin-top: 5px;
    margin-left: 5px;
    font-weight: bold;
  }

  .fondo{
    background-color:#F6F8FA;
    font-weight: bold;
    
  }
  